import React, { useEffect, useMemo, useState } from 'react';

import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Home from '../home/Home';
import Login from '../login/Login';
import { useSessionStorage } from 'usehooks-ts';
import Header from '../header/Header';
import BestPage from '../best-table/Best';
import VDayPage from '../vday-table/VDay';
import Year1Page from '../1year/Year1';



export default function Landing() {
    const navigate = useNavigate();

    const [didUserLogin, setUserLogin] = useSessionStorage('didUserLogin', false)
    const [matchingPassword, setMatchingPassword] = useState<boolean>(false)

    useEffect(() => {
        if (!didUserLogin && matchingPassword) {
            setUserLogin(true);
        }
    }, [didUserLogin, matchingPassword, setUserLogin])

    const isUserLoggedIn = useMemo(() => {
        return didUserLogin || matchingPassword
    }, [didUserLogin, matchingPassword]);


    useEffect(() => {
        if (!isUserLoggedIn) {
            navigate('/login');
        }
    }, [isUserLoggedIn, navigate])

    return (
        <React.Fragment>
            <Header />
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login setMatchingPassword={setMatchingPassword} />} />
                <Route path="/best" element={<BestPage />} />
                <Route path="/vday" element={<VDayPage />} />
                <Route path="/year1" element={<Year1Page />} />
                {/* Default route */}
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </React.Fragment>
    );
}

