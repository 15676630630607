import React from 'react';

import './Home.css';
import { Link } from 'react-router-dom';

export default function Home() {

  return (
    <React.Fragment>
      <div className="nav-container">
        <Link to="/best" className="card best-card">Best Edition</Link>
        <Link to="/vday" className="card vday-card">VDay Edition</Link>
        <Link to="/year1" className="card year1">1 Year!!!</Link>
      </div>
    </React.Fragment>
  );
}

