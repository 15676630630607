

export const REASONS_WHY_I_LOVE_YOU = [
    "You're soooo caring. Thanks for all the boroline.",
    'Your eyes convey all your emotions',
    "You're forgiving. 🙏",
    "You're great at planning trips, can't wait for all our future vacays 🏖️",
    "You'll be the most amazing mom there is 👧 🧒",
    "You're 0 or 100 just like me",
    "You bring up great points in an argument - I'm scared of you",
    "You're almost always right and I should just listen to you.",
    "You're extremely honest and open with everyone",
    "You're a great friend to have - you really care about your friends.",
    "You're so fun to go out with and always a great time (provided the music is good) 🎶 💃",
    "I can have deep conversations with you and you'd actually think about your responses",
    'Your big ass eyes 👀',
    "You're beautiful - even when you're mad at me 🤬",
    "You're open to changing your ways, if you think your actions impact me 😡 💀 🚫",
    "You put up with my stupid jokes.",
    "You have an opinion of your own and you're not afraid to voice it.",
    "You like Audi's over BMWs 🚗 🚗 🚗",
    "You're independent",
    "You're sooo unique"
]


export const THINGS_I_LOVE_ABOUT_YOU = [
"I love the way you get overly excited sometimes, so much so that you can't control your excitement and it shows. 🤩",
"I love when you sing to Oreo and bond with him. It melts my heart. 🎤",
`I love it when you send me 'I miss you' texts in the middle of the work day. 💬`,
"I love when you snore and talk to me while you're asleep. 😴",
"I love that you put up with my shit, regardless of how extreme I am sometimes. 🙏",
"I love when you teach Oreo tricks. Should teach him a new one (unrelated to Mich) every month? 🐶🐶🐶",
"I love the moment when the anger dies down, and it's just love in your eyes. 🥲",
"I love how you're always 'down' even when you aren't really down. 🥂",
"I love it when you put aside that ego of yours and tell me what you actually want. For example a hug...🤗",
"I love how you put in genuine effort with everyone and show them that you really care.",
"I love the Oreo voice.",
"I love it when I wake up to find you hugging me and sleeping",
"I love that the anger SLA has gone down by a lot and the time to lovey-dovey is not as long anymore. ⬇️",
"I love it when you're vulnerable with me. I know it's hard for you but it makes me feel closer to you. 💗"
]


export function getBestImageURL(num : number): string {
    return `https://bub-best.s3.amazonaws.com/best/${num}.png`
}

export function getVDAYUrl(num : number): string {
    return `https://bub-best.s3.amazonaws.com/vday/${num}.png`
}

