import * as React from 'react';

import CardItem from '../home/card/Card'
import { Stack } from '@mui/material';

import './Table.css';

const shuffle = (array: number[]): number[] => {
    const shuffled = array.slice();
    let currentIndex = shuffled.length;
    let temporaryValue, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = shuffled[currentIndex];
        shuffled[currentIndex] = shuffled[randomIndex];
        shuffled[randomIndex] = temporaryValue;
    }
    return shuffled;
};

const getIndexArr = (limit: number): number[] => {
    const arr = []
    for (let i = 1; i <= limit; i++) {
        arr.push(i)
    }
    return shuffle(arr)
};




interface TableProps {
    tableLimit: number
    reasons: string[]
    getImageUrl(num: number): string
    cardType: string,
}

export default function Table({ tableLimit, reasons, getImageUrl, cardType }: TableProps) {

    const indexArr = getIndexArr(tableLimit)

    return (
        <div id="Table">
            <Stack spacing={6} sx={{ maxWidth: "80vw", marginBottom: '120px' }}>
                {
                    indexArr.map((shuffledIndex, index) => {
                        return <CardItem cardType={cardType} count={index + 1} key={index} designID={index % 5} reason={reasons[shuffledIndex - 1]} imageUrl={getImageUrl(shuffledIndex)} />
                    })
                }
            </Stack>
        </div>
    );
}