import React from 'react';
import { useLocation } from 'react-router-dom';

import './Header.css';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { AccessTime, QuestionMarkOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


import { useCallback, useState, useEffect } from 'react'
import Heart from './Heart';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 360,
    backgroundColor: '#d6eadf',
    border: '.5px solid #000',
    borderRadius: "30px",
    boxShadow: 24,
    p: 4,
};

export default function Header() {

    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showClockModal, setShowClockModal] = useState(false)

    const onClickClock = useCallback(() => { setShowClockModal(true) }, [setShowClockModal]);
    const onClickInfo = useCallback(() => { setShowInfoModal(true) }, [setShowInfoModal])

    return (
        <div id="Header">
            <div id="Heart">
                <Heart />
            </div>
            <div id="Buttons">
                <IconButton
                    edge="end"
                    onClick={onClickClock}
                >
                    <AccessTime fontSize='medium' />
                </IconButton>
                <IconButton
                    edge="end"
                    onClick={onClickInfo}
                >
                    <QuestionMarkOutlined fontSize='medium' />
                </IconButton>
            </div>
            <Modal
                open={showInfoModal}
                onClose={() => setShowInfoModal(false)}
            >
                <>
                    <InfoModalContent />
                </>
            </Modal>
            <Modal
                open={showClockModal}
                onClose={() => setShowClockModal(false)}
            >
                <>
                    <TimingModalContent />

                </>
            </Modal>
        </div >
    );
}

function monthDiff(dateFrom: Date, dateTo: Date): number {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

function getMonthString(): string {
    const numMonths = monthDiff(ANNIVERSARY_DATE, CURRENT_DATE)
    return `${numMonths} month${numMonths === 1 ? "" : "s"}`
};

const ANNIVERSARY_DATE = new Date("2023-06-16T00:00:00")
const CURRENT_DATE = new Date()


function TimingModalContent(): React.ReactElement {

    const { days, hours, minutes, seconds } = useTimer(ANNIVERSARY_DATE);

    const getTimeString = useCallback(() => {
        let dayString = days + " day" + (days !== 1 ? "s" : "");
        let hourString = hours + " hour" + (hours !== 1 ? "s" : "");
        let minutesString = minutes + " minute" + (minutes !== 1 ? "s" : "");
        return `${dayString} ${hourString} ${minutesString}`
    }, [days, hours, minutes]);

    const calculateCompletionPercentage = useCallback(() => {
        const timespanInYears = ((days * 86400) + (hours * 3600) + (minutes * 60) + seconds) / (DAY * 365)
        const sevenSquaredLifetimes = 62589268
        return (timespanInYears / sevenSquaredLifetimes * 100).toFixed(16)
    }, [days, hours, minutes, seconds]);

    const currentDay = CURRENT_DATE.getDate()

    return (
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Fun time facts:
            </Typography>
            {
                currentDay === 16 &&
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {`Happy ${getMonthString()}!!! 🎉 🍾 🥳`}
                </Typography>
            }
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`${getTimeString()} has passed since the "so are we together?"`}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`We have only completed ${calculateCompletionPercentage()}% of the 7^7 lifetimes you've promised me.`}
            </Typography>
        </Box >
    );
}


function InfoModalContent(props: {}): React.ReactElement {

    const location = useLocation();

    if (location.pathname === "/vday") {
        return <Box sx={modalStyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Hey babe, Happy Valentine's Day!! Just wanted to highlight 14 things that you do that I absolutely love. Thanks for all of the love in the past 8 months.
            </Typography>
        </Box>
    }

    return (
        <Box sx={modalStyle}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Hey bubby, I love you so much and I think you're amazing. This higlights some of the reasons why you're the absolute best. Think of this like a portfolio for our relationship idk??
                <br />
                <br />
                I plan to keep updating this - if you're not nice to me, V2 will have mean things. 🙃
            </Typography>
        </Box>
    );
}



const SECOND = 1_000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function useTimer(startDate: Date, interval = SECOND) {
    const [timespan, setTimespan] = useState(Date.now() - startDate.getTime());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimespan((_timespan) => _timespan + interval);
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [interval]);

    useEffect(() => {
        setTimespan(Date.now() - startDate.getTime());
    }, [startDate]);

    return {
        days: Math.floor(timespan / DAY),
        hours: Math.floor((timespan / HOUR) % 24),
        minutes: Math.floor((timespan / MINUTE) % 60),
        seconds: Math.floor((timespan / SECOND) % 60),
        timespan: (timespan / SECOND)
    };
}