import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography } from '@mui/material';


interface VideoPlayerProps {
    videoUrl: string
    posterUrl: string
}

const VideoPlayer = ({ videoUrl, posterUrl }: VideoPlayerProps) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        // Pause the video by default
        if (videoRef.current) {
            videoRef.current.pause();
        }
    }, [videoUrl]);

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying((isPlaying) => !isPlaying);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            {videoUrl ? (
                <Card>
                    <video
                        ref={videoRef}
                        controls
                        onClick={handleVideoClick}
                        poster={posterUrl}
                        style={{
                            width: '100%',
                            maxWidth: '600px',
                            height: 'auto',
                            maxHeight: '700px',
                            cursor: 'pointer',
                        }}
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Card>
            ) : (
                <Typography variant="h6">Loading video...</Typography>
            )}
        </Box>
    );
};

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    posterUrl: PropTypes.string.isRequired,
};

export default VideoPlayer;