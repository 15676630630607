import * as React from 'react';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { TextField, IconButton } from '@mui/material';
import { useCallback, useState } from 'react'
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';



const validPasswords = ['06162023', '06/16/23', '06/16/2023', '061623']

interface LoginProps {
  setMatchingPassword: React.Dispatch<React.SetStateAction<boolean>>
}


export default function Login({ setMatchingPassword }: LoginProps) {
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)


  const onClick = useCallback(() => {
    const isValidPassword = validPasswords.some(passwordOption => password === passwordOption)
    setMatchingPassword(isValidPassword)
    if (!isValidPassword) {
      setShowError(true)
    } else {
      navigate("/home")
    }
  }, [password, setMatchingPassword, setShowError, navigate])

  return (
    <Stack spacing={2}>
      <TextField
        id="outlined-controlled"
        label="Password"
        value={password}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(event.target.value);
        }}
        helperText="Important date maybe???"
        type="password"
        error={showError}
      />
      <IconButton aria-label="delete" color="error" onClick={onClick}>
        <FavoriteBorderIcon />
      </IconButton>
    </Stack >
  );
}