import React from 'react';
import './FallingHearts.css'; // Import the CSS file


export function FallingHearts() {
    return (
        <div className="background">
            <div className="heart x1" style={{ zIndex: 9999 }} ></div>
            <div className="heart x2" style={{ zIndex: 9999 }}></div>
            <div className="heart x3" style={{ zIndex: 9999 }}></div>
            <div className="heart x4" style={{ zIndex: 9999 }}></div>
            <div className="heart x5" style={{ zIndex: 9999 }}></div>
            <div className="altheart x6" style={{ zIndex: 9999 }}></div>
            <div className="altheart x6" style={{ zIndex: 9999 }}></div>
            <div className="heart x7" style={{ zIndex: 9999 }}></div>
            <div className="heart x8" style={{ zIndex: 9999 }}></div>
            <div className="heart x9" style={{ zIndex: 9999 }}></div>
            <div className="altheart x7" style={{ zIndex: 9999 }}></div>
            <div className="altheart x8" style={{ zIndex: 9999 }}></div>
            <div className="altheart x9" style={{ zIndex: 9999 }}></div>
            <div className="heart x10" style={{ zIndex: 9999 }}></div>
            <div className="heart x2" style={{ zIndex: 9999 }}></div>
            <div className="heart x3" style={{ zIndex: 9999 }}></div>
            <div className="heart x4" style={{ zIndex: 9999 }}></div>
            <div className="heart x5" style={{ zIndex: 9999 }}></div>
            <div className="altheart x6" style={{ zIndex: 9999 }}></div>
            <div className="altheart x6" style={{ zIndex: 9999 }}></div>
            <div className="heart x7" style={{ zIndex: 9999 }}></div>
            <div className="heart x8" style={{ zIndex: 9999 }}></div>
            <div className="heart x9" style={{ zIndex: 9999 }}></div>
            <div className="altheart x7" style={{ zIndex: 9999 }}></div>
            <div className="altheart x8" style={{ zIndex: 9999 }}></div>
            <div className="altheart x9" style={{ zIndex: 9999 }}></div>
        </div>
    );
}