import React from 'react';


import { Paper } from '@mui/material';
import VideoPlayer from './VideoPlayer';

const videoUrl = "https://bub-best.s3.amazonaws.com/year1/video.mp4"
const posterUrl = "https://bub-best.s3.amazonaws.com/year1/poster.png"

export default function Year1Page() {
    return (
        <VideoPlayer videoUrl={videoUrl} posterUrl={posterUrl} />
    );
}
