import React from 'react';
import './App.css';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import Landing from './landing/landing';
import { FallingHearts } from './falling-hearts/FallingHearts';

const THEME = createTheme({
  typography: {
    "fontFamily": `"Comic Sans MS", "Gotham", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

function WrappedApp() {
  return <Router>
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  </Router>
}


function App() {
  const location = useLocation();

  if (location.pathname === "/vday") {
    return <div className="App">
      <FallingHearts />
      <header className="Alt-header">
        <Landing />
      </header>
    </div>

  }

  return (
    <div className="App">
      <header className="App-header">
        <Landing />
      </header>
    </div>
  );
}

export default WrappedApp;
