
import * as React from 'react';


import './Card.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';


interface CardItemProps {
    count: number
    designID: number
    reason: string
    imageUrl: string
    cardType: string
}

export default function CardItem({ count, designID, reason, imageUrl, cardType }: CardItemProps) {
    return (
        <Card id={cardType === "vday" ? "CardItemVday" : `CardItem${designID + 1}`} variant="outlined" sx={{ padding: "32px", borderRadius: "30px", zIndex: 20, position: "relative" }}  >
            <CardMedia
                id="CardMedia"
                component="img"
                image={imageUrl}
                alt="AB Image"
            />
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Reason #{count}
                </Typography>
                <Typography variant="body2" align='left'>
                    {reason}
                </Typography>
            </CardContent>
        </Card>
    )

}