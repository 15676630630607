import React from 'react';

import Table from '../table/Table';

import { REASONS_WHY_I_LOVE_YOU, getBestImageURL } from '../config/constant';


export default function BestPage() {
    return (
        <Table cardType="best" tableLimit={20} reasons={REASONS_WHY_I_LOVE_YOU} getImageUrl={getBestImageURL} />
    );
}

