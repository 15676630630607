import React from 'react';

import Table from '../table/Table';

import { THINGS_I_LOVE_ABOUT_YOU, getVDAYUrl } from '../config/constant';

export default function VDayPage() {
    return (
        <Table cardType='vday' tableLimit={14} reasons={THINGS_I_LOVE_ABOUT_YOU} getImageUrl={getVDAYUrl} />
    );
}

